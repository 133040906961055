import React from "react";
import { Spinner } from "reactstrap";
import { useAuth } from "infrastructure/context/auth-context";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/browser";

const AuthenticatedApp = React.lazy(() => import("authenticated-app"));
const UnAuthenticatedApp = React.lazy(() => import("unAuthenticated-app"));

Sentry.init({
  dsn: process.env.REACT_APP_SENTRYDSN,
  environment: process.env.REACT_APP_ENV
});

toast.configure();

const App = () => {
  const user = useAuth();

  return (
    <React.Suspense fallback={<Spinner color="info" />}>
      <ToastContainer hideProgressBar={true} autoClose={2000} />
      {user ? <AuthenticatedApp /> : <UnAuthenticatedApp />}
    </React.Suspense>
  );
};

export default App;
