import React from "react";
import { parse } from "query-string";

export type User = {
  userId: number;
  token: string;
};

const AuthContext = React.createContext<User | null | undefined>(undefined);

function AuthProvider(props: object) {
  let user: User | null = null;

  const temp = window.sessionStorage.getItem("AUTH");

  if (temp) {
    user = JSON.parse(temp);
  }

  if (!user) {
    const result = readUserFromParams();
    if (result) {
      user = result;
      window.sessionStorage.setItem("AUTH", JSON.stringify(user));
    }
  }

  return <AuthContext.Provider value={user} {...props} />;
}

function readUserFromParams() {
  let { userid, token } = parse(window.location.search);
  if (!userid || userid instanceof Array || (!token || token instanceof Array)) {
    return null;
  }

  return { userId: parseInt(userid as string, 10), token: (token as string) };
}

function useAuth() {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
